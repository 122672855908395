import { atom } from "recoil";

interface bd {
  id: string;
  name: string;
  surName?: string;
  city: string;
  adress?: string;
  email: string;
  subscription: number;
  category: string;
  subCategory: string;
  profilePic: string;
  created: string;
  accessToken?: string;
}

const userAtom = atom({
  key: "user",
  default: {} as bd,
});

const accessTokenAtom = atom({
  key: "accessToken",
  default: "" as string,
});

export { userAtom, accessTokenAtom };

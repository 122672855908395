import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { verifyEmail } from "../api";

const VerifyEmail = () => {
  // const token = useParams();

  // useEffect(() => {
  //   verifyEmail(token)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <div className="flex h-screen lg:items-center mx-8 flex-wrap justify-between">
      <Plan />
      <Plan />
      <Plan />
      <Plan />
    </div>
  );
};

function Plan() {
  return (
    <div className="shadow-lg m-auto rounded-2xl w-64 bg-white dark:bg-gray-800 p-4">
      <p className="text-black dark:text-white text-3xl font-bold">Essential</p>
      <p className="text-gray-500 dark:text-gray-300 text-sm mb-4">Paket</p>
      <p className="text-black dark:text-white  text-3xl font-bold">$99</p>
      <p className="text-gray-500 dark:text-gray-300 text-sm mb-4">mesecno</p>
      <button
        type="button"
        className="w-56 m-auto px-3 py-3 text-sm shadow border border-black rounded-lg text-black bg-white hover:bg-black hover:text-white dark:hover-text-gray-900 dark:hover:bg-gray-100 "
      >
        Kupi
      </button>
      <ul className="text-sm text-black dark:text-white w-full mt-6 mb-6">
        <li className="mb-3 flex items-center">
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 1792 1792"
          >
            <path d="M1152 896q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181zm-256-544q-148 0-273 73t-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273-73-273-198-198-273-73zm768 544q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
          </svg>
          Bez restrikcija na broj oglasa
        </li>
        <li className="mb-3 flex items-center">
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 1792 1792"
          >
            <path d="M1152 896q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181zm-256-544q-148 0-273 73t-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273-73-273-198-198-273-73zm768 544q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
          </svg>
          Povecana Vidljivost
        </li>
        <li className="mb-3 flex items-center">
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 1792 1792"
          >
            <path d="M1152 896q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181zm-256-544q-148 0-273 73t-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273-73-273-198-198-273-73zm768 544q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
          </svg>
          24/24 support
        </li>
      </ul>
      <span className="w-56 block bg-gray-100 h-1 rounded-lg my-2"></span>
      <ul className="text-sm text-black dark:text-white w-full mt-6 mb-6">
        <li className="mb-3 flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#10b981"
            viewBox="0 0 1792 1792"
          >
            <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
          </svg>
          <div>Svi linkovi</div>
        </li>
        <li className="mb-3 flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#10b981"
            viewBox="0 0 1792 1792"
          >
            <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
          </svg>
          <div>Najbolje rangiranje</div>
        </li>
        <li className="mb-3 flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#10b981"
            viewBox="0 0 1792 1792"
          >
            <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
          </svg>
          <div>Nesto trece</div>
        </li>
      </ul>
    </div>
  );
}

export default VerifyEmail;

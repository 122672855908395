import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function startApp() {
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <App />
                </RecoilRoot>
            </QueryClientProvider>
        </React.StrictMode>,
        document.getElementById("root")
    );
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

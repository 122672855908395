import React from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom, accessTokenAtom } from "../atoms";
import { NavTransparent, NavWhite } from "../components/Nav";

const Me: React.FC = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [token, setToken] = useRecoilState(accessTokenAtom);
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const { ref, inView, entry } = useInView({
    threshold: 0.9,
  });

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("avatar", data.avatar[0]);
    console.log(token);
    const res = await fetch("https://api.beem.rs/avatar", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include", // include, *same-origin, omit
    }).then((res) => res.json());
    alert(JSON.stringify(res));
  };
  return (
    <div>
      {inView ? <NavTransparent /> : <NavWhite />}
      <h1>Pozdrav{user.name}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="file" name="avatar" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Me;

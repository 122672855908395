import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { attemptRefresh } from "./api";
import { userAtom, accessTokenAtom } from "./atoms";
import Routes from "./Routes";

const App: React.FC = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [token, setToken] = useRecoilState(accessTokenAtom);

  //   useEffect(() => {
  //     attemptRefresh()
  //       .then((data) => {
  //         console.log(data);
  //         if (data.account) {
  //           setUser(data.account);
  //           setToken(data.account.accessToken!);
  //         }
  //       })
  //       .catch((e) => console.log(e));
  //   }, []);

  return <Routes />;
};

export default App;

import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Me from "./pages/Me";
import VerifyEmail from "./pages/VerifyEmail";
import { useRecoilState } from "recoil";
import { userAtom } from "./atoms";

const Routes: React.FC = () => {
  const [user, setUser] = useRecoilState(userAtom);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/pricing" component={VerifyEmail} />
        <Route
          exact
          path="/me"
          render={() => {
            return user ? <Me /> : <Redirect to="/login" />;
          }}
        />
        <Route path="/activate/:token" component={VerifyEmail} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

function NavWhite() {
    return (
        <div className="fixed w-screen top-0 bg-white flex items-center justify-between p-2 md:p-4 flex-nowrap mb-1 z-50">
            <div className="text-gray-900 mx-3 text-2xl font-bold tracking-tighter align-middle md:block md:text-4xl md:font-black min-w-max">
                be <div className="inline-block transform -rotate-45">E</div>m
            </div>
            <div className="flex items-center justify-center flex-grow max-w-xl p-3 mx-auto rounded-full shadow-lg bg-gray-50">
                <input
                    className="flex-grow block w-full pl-3 text-gray-500 bg-gray-50 focus:outline-none"
                    type="text"
                    placeholder="Pretrazi korisnike ili reklame..."
                />
                <div className="flex justify-center p-2 align-middle bg-red-500 rounded-full">
                    <button
                        className="text-white"
                        style={{ height: "20px", width: "20px" }}
                        type="submit"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="mx-3 align-middle min-w-max hidden sm:block sm:flex sm:flex-nowrap">
                <div className="px-4 py-1 rounded-full hover:bg-gray-400 text-black hover:text-white">
                    <span>Pridruzi se</span>
                </div>
                <div className="px-4 py-1 rounded-full bg-white flex flex-nowrap align-middle justify-items-center">
                    <div className="mr-3">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </div>
                    <div>
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavWhite;

import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "../atoms";
import { useInView } from "react-intersection-observer";
import { NavTransparent, NavWhite } from "../components/Nav";
import grapes from "./grapes.jpg";
import bg from "./bg.jpg";

interface Props extends RouteComponentProps {}

const Home: React.FC<Props> = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0.9,
  });
  const history = useHistory();
  const [user, setUser] = useRecoilState(userAtom);

  return (
    <>
      <div className="-pt-20" ref={ref}></div>
      <div className="pt-20 pb-8" style={{ height: "90vh" }}>
        {inView ? <NavTransparent /> : <NavWhite />}

        <div
          className="h-full m-8 rounded-md"
          style={{
            backgroundImage: `url("bg2.jpg")`,
            height: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundBlendMode: "multiply",
          }}
        >
          <div className="w-full shadow-xl px-5 z-10 h-full flex flex-col md:flex-row justify-between items-center md:flex-nowrap md:justify-between md:items-center">
            <div className="md:w-1/3 md:h-full flex flex-col justify-center space-y-10 items-center md:p-10">
              {/* <span className="text-9xl text-gray-500 tracking-tight font-extrabold">Beem</span> */}
              <div className="text-6xl md:text-9xl font-extrabold ...">
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
                  Beem
                </span>
                <span className="block text-gray-900 mb-4 text-xl md:text-3xl font-extrabold">
                  Sve na jednom mestu
                </span>
              </div>
            </div>

            <div className="mb-5 flex flex-col md:min-w-1/2 md:min-h-1/2">
              <div className="">
                <span className="text-2xl font-bolder text-gray-800 md:font-extrabold tracking-normal">
                  Nasi zlatni sponzori
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto my-12">
        <div className="relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl md:h-72 mx-2">
          <div className="z-0 order-1 md:order-2 relative w-full md:w-2/5 h-80 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
            <div
              className="absolute inset-0 w-full h-full object-fill object-center bg-blue-400 bg-opacity-30 bg-cover bg-bottom"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1533808510407-976bfd509dc2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)`,
                backgroundBlendMode: "multiply",
              }}
            ></div>
            <div className="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
              <h3 className="w-full font-bold text-2xl text-white leading-tight mb-2">
                Beem
              </h3>
              <h4 className="w-full text-xl text-gray-100 leading-tight">
                O nama
              </h4>
            </div>
            <svg
              className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>

          <div className="z-10 order-2 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
            <div className="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
              <h4 className="hidden md:block text-xl text-gray-500">
                O nama u par reci
              </h4>
              <h3 className="hidden md:block font-bold text-2xl text-gray-800">
                Beem
              </h3>
              <p className="text-gray-600 text-justify">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Ratione itaque perspiciatis quod sapiente quidem, vero
                consectetur quae iure error tempore reprehenderit unde veritatis
                fugit iusto nemo amet. Assumenda, quam facilis.
              </p>
              <a
                className="flex items-baseline mt-3 text-blue-600 hover:text-blue-900 focus:text-blue-900"
                href=""
              >
                <span>Usluge i ponuda</span>
                <span className="text-xs ml-1">&#x279c;</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto my-6">
        <h1 className="block font-bold text-3xl text-gray-800">Oglasi</h1>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center md:flex-nowrap md:justify-between md:items-center">
        <Oglas />
        <Oglas />
        <Oglas />
        <Oglas />
      </div>

      <div className="max-w-screen-xl rounded-lg shadow-xl p-4 bg-white dark:bg-gray-800 my-12 mx-auto px-4 sm:px-6 lg:px-8 relative py-26 lg:mt-20">
        <div className="relative">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2 lg:max-w-2xl ml-auto">
              <p className="text-base leading-6 text-indigo-500 font-semibold uppercase">
                Naslov
              </p>
              <h4 className="mt-2 text-2xl leading-8 font-extrabold text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure.
              </h4>
              <p className="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                vero facilis molestiae eum ratione. Quidem distinctio facilis
                dolor officia.
              </p>
              <ul className="mt-8 md:grid md:grid-cols-2 gap-6">
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800 dark:text-green-500 drark:bg-transparent">
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                      Live modifications
                    </span>
                  </div>
                </li>
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800 dark:text-green-500 drark:bg-transparent">
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                      Data tracker
                    </span>
                  </div>
                </li>
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800 dark:text-green-500 drark:bg-transparent">
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                      24/24 support
                    </span>
                  </div>
                </li>
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-green-100 text-green-800 dark:text-green-500 drark:bg-transparent">
                      <svg
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span className="ml-4 text-base leading-6 font-medium text-gray-500 dark:text-gray-200">
                      Free tips to improve work time
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="mt-10 lg:-mx-4 relative relative-20 lg:mt-0 lg:col-start-1">
              <div className="relative space-y-4">
                <div className="flex items-end justify-center lg:justify-start space-x-4">
                  <img
                    className="rounded-lg shadow-lg w-32 md:w-56"
                    width="300"
                    src={grapes}
                    alt="1"
                  />
                  <img
                    className="rounded-lg shadow-lg w-40 md:w-64"
                    width="360"
                    src={bg}
                    alt="2"
                  />
                </div>
                <div className="flex items-start justify-center lg:justify-start space-x-4 ml-12">
                  <img
                    className="rounded-lg shadow-lg w-24 md:w-40"
                    width="370"
                    src={grapes}
                    alt="3"
                  />
                  <img
                    className="rounded-lg shadow-lg w-32 md:w-56"
                    width="300"
                    src={grapes}
                    alt="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 md:flex-row justify-between items-center md:flex-nowrap md:justify-between md:items-center">
        <Oglas />
        <Oglas />
        <Oglas />
        <Oglas />
      </div>

      <Footer />
    </>
  );
};

function Footer() {
  return (
    <footer className="bg-gray-50 mt-12 dark:bg-gray-800 pt-4 pb-8 xl:pt-8">
      <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8 text-gray-400 dark:text-gray-300">
        <ul className="text-lg font-light pb-8 flex flex-wrap justify-center">
          <li className="w-1/2 md:w-1/3 lg:w-1/3">
            <div className="text-center">
              <h2 className="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                Usluge
              </h2>
              <ul>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Oglasavanje</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Reklamiranje</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Komercijala</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Ne znam</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="w-1/2 md:w-1/3 lg:w-1/3">
            <div className="text-center">
              <h2 className="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                Kontakt
              </h2>
              <ul>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Github</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Facebook</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Twitter</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">LinkedIn</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="w-1/2 md:w-1/3 lg:w-1/3">
            <div className="text-center">
              <h2 className="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                Pravno
              </h2>
              <ul>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Uslovi Koriscenja</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Politika privatnosti</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Prava</a>
                </li>
                <li className="mb-4 hover:text-gray-800 dark:hover:text-white transition-colors duration-200">
                  <a href="#">Nesto</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div className="pt-8 flex border-t border-gray-200 max-w-xs mx-auto items-center justify-between">
          <a href="#">
            <svg
              width="20"
              height="20"
              fill="currentColor"
              className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
            </svg>
          </a>
          <a href="#">
            <svg
              width="20"
              height="20"
              fill="currentColor"
              className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path>
            </svg>
          </a>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
              viewBox="0 0 1792 1792"
            >
              <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z"></path>
            </svg>
          </a>
          <a href="#">
            <svg
              width="20"
              height="20"
              fill="currentColor"
              className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
            </svg>
          </a>
          <a href="#">
            <svg
              width="20"
              height="20"
              fill="currentColor"
              className="text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1551 1476q15-6 26-3t11 17.5-15 33.5q-13 16-44 43.5t-95.5 68-141 74-188 58-229.5 24.5q-119 0-238-31t-209-76.5-172.5-104-132.5-105-84-87.5q-8-9-10-16.5t1-12 8-7 11.5-2 11.5 4.5q192 117 300 166 389 176 799 90 190-40 391-135zm207-115q11 16 2.5 69.5t-28.5 102.5q-34 83-85 124-17 14-26 9t0-24q21-45 44.5-121.5t6.5-98.5q-5-7-15.5-11.5t-27-6-29.5-2.5-35 0-31.5 2-31 3-22.5 2q-6 1-13 1.5t-11 1-8.5 1-7 .5h-10l-3-.5-2-1.5-1.5-3q-6-16 47-40t103-30q46-7 108-1t76 24zm-394-443q0 31 13.5 64t32 58 37.5 46 33 32l13 11-227 224q-40-37-79-75.5t-58-58.5l-19-20q-11-11-25-33-38 59-97.5 102.5t-127.5 63.5-140 23-137.5-21-117.5-65.5-83-113-31-162.5q0-84 28-154t72-116.5 106.5-83 122.5-57 130-34.5 119.5-18.5 99.5-6.5v-127q0-65-21-97-34-53-121-53-6 0-16.5 1t-40.5 12-56 29.5-56 59.5-48 96l-294-27q0-60 22-119t67-113 108-95 151.5-65.5 190.5-24.5q100 0 181 25t129.5 61.5 81 83 45 86 12.5 73.5v589zm-672 21q0 86 70 133 66 44 139 22 84-25 114-123 14-45 14-101v-162q-59 2-111 12t-106.5 33.5-87 71-32.5 114.5z"></path>
            </svg>
          </a>
        </div>
        <div className="text-center pt-10 sm:pt-12 font-light flex items-center justify-center">
          Created by Nikola
        </div>
      </div>
    </footer>
  );
}

function Oglas() {
  return (
    <div className="flex max-w-md pt-6 bg-white shadow-xl rounded-lg overflow-hidden">
      <div
        className="w-1/3 bg-cover"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1533808510407-976bfd509dc2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)`,
        }}
      ></div>
      <div className="w-2/3 p-4">
        <h1 className="text-gray-900 font-bold text-2xl">Oglas</h1>
        <p className="mt-2 text-gray-600 text-sm">
          You can&#x27;t buy your future, but you can do it. Money is nothing,
          you&#x27;r everything.
        </p>
        <div className="flex item-center mt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-3 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
          </svg>{" "}
          <span>Valjevo</span>
        </div>
        <div className="flex item-center justify-between mt-3">
          <h1 className="text-gray-700 font-bold text-xl">€220</h1>
          <button className="px-3 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded">
            Pogledajte Oglas
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
